
    const DEFAULT_OPTIONS = {
        html                 : false,
        loader               : false,
        reverse              : false,
        backdropClose        : true,
        okText               : 'Continue',
        cancelText           : 'Close',
        view                 : null,
        type                 : 'basic',
        window               : 'confirm',
        message              : 'Proceed with the request?',
        clicksCount          : 3,
        animation            : 'zoom',
        customClass          : '',
        verification         : 'continue',
        verificationHelp     : 'Type "[+:verification]" below to confirm',
        promptHelp      	 : 'Type in the box below and click "[+:okText]"'
    };

    export default {
        data(){
            return {
                dialogsARR: []
            }
        },
        created() {
            if(process.client){
                this.$nuxt.$on("dialogOpen",this.commit);
                document.addEventListener('keydown', this.escapeKeyListener, { passive : true })
            }
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.escapeKeyListener);
            this.$nuxt.$off("dialogOpen",this.commit);
           
        },
        watch: {
            'dialogsARR': {
                handler (dialogs) {
                    let clsName = 'dg-open'
                    const body = document.getElementsByTagName('body')[0]

                    if (!body) {
                        return
                    }

                    if (dialogs.length && !body.classList.contains(clsName)) {
                        body.classList.add(clsName)
                    } else if (!dialogs.length && body && body.classList.contains(clsName)) {
                        body.classList.remove(clsName)
                    }
                }
            }
        },
        methods: {
            commit(data){
                data.escapeKeyClose = false;
                data = {
                    ...DEFAULT_OPTIONS,
                    ...data
                }
                this.$store.commit('layout/setModalOpen',true);
                this.dialogsARR.push(data)
            },
            destroyDialog(dialogId){
                let dialogIndex = this.firstIndex(this.dialogsARR, dialogId, 'id');
                this.$store.commit('layout/setModalOpen',false);

                if (dialogIndex !== -1) {
                    this.$delete(this.dialogsARR, dialogIndex)
                }
            },
            escapeKeyListener(e){
                if (e.keyCode !== 27) return
                let dialogIndex = (-1 + this.dialogsARR.length)
                if(dialogIndex > -1){
                    this.$set(this.dialogsARR[dialogIndex], 'escapeKeyClose', true)
                }
            },
            firstIndex(arr, search, prop) {
                let i
                let limit = arr.length
            
                for (i = 0; i < limit; i++) {
                    if (arr[i][prop] === search) {
                        return i
                    }
                }
                return -1
            }
        }
    }
